import React, { useState } from "react";
import Chatbot, { ChatBotProvider } from "react-chatbotify";
import ai_avatar from "../../assets/svg/modern_ai.svg";

const CustomChatbot = () => {
  const [form, setForm] = useState({
    name: "",
    budget: "",
    property_type: "",
    location: "",
    bedrooms: "",
    contact_info: "",
  });

  const [liveAgentRequested, setLiveAgentRequested] = useState(false);

  const formStyle = {
    backgroundColor: "#f9f9f9",
    padding: "20px",
    borderRadius: "10px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    maxWidth: "400px",
    margin: "20px auto",
    fontFamily: "Arial, sans-serif",
    color: "#333",
  };

  // Define the basic and advanced chatbot flow
  const flow = {
    start: {
      message:
        "Hello! Welcome to our advanced real estate services. May I know your name?",
      function: (params) => setForm({ ...form, name: params.userInput }),
      path: "ask_budget",
    },
    ask_budget: {
      message: (params) =>
        `Nice to meet you, ${params.userInput}. What is your preferred budget range for the property?`,
      function: (params) => setForm({ ...form, budget: params.userInput }),
      path: "ask_property_type",
    },
    ask_property_type: {
      message:
        "Great! What type of property are you looking for? (e.g., Apartment, House, Commercial)",
      function: (params) =>
        setForm({ ...form, property_type: params.userInput }),
      path: "ask_location_preference",
    },
    ask_location_preference: {
      message:
        "Which location or area are you interested in? (e.g., city, neighborhood, zip code)",
      function: (params) => setForm({ ...form, location: params.userInput }),
      path: "ask_bedrooms",
    },
    ask_bedrooms: {
      message: "How many bedrooms do you need in the property?",
      options: ["1", "2", "3", "4+"],
      chatDisabled: true,
      function: (params) => setForm({ ...form, bedrooms: params.userInput }),
      path: "ask_contact_info",
    },
    ask_contact_info: {
      message:
        "Can you please provide your contact number so we can reach out to you with property options?",
      function: (params) =>
        setForm({ ...form, contact_info: params.userInput }),
      path: "live_agent_or_end",
    },
    live_agent_or_end: {
      message:
        "Would you like to speak to a live agent or contact us via WhatsApp?",
      options: ["Live Agent", "WhatsApp"],
      function: async (params) => {
        switch (params.userInput) {
          case "Live Agent":
            window.open("tel:8660116678");
            break;
          case "WhatsApp":
            window.open(
              "https://api.whatsapp.com/send?phone=+918892361448&text=Hi,%20I'm%20interested%20in%20real%20estate%20services!"
            );
            return "Opening WhatsApp chat...";
          default:
            return "Unknown input.";
        }
      },
      path: "end",
    },
    end: {
      message: "Thank you for using our service!",
    },
  };

  const settings = {
    general: {
      primaryColor: "#529092",
      secondaryColor: "#439092",
      fontFamily: "Poppins sans-serif",
      showHeader: true,
      showFooter: true,
      showInputRow: true,
      embedded: false,
      desktopEnabled: true,
      mobileEnabled: true,
      flowStartTrigger: "ON_LOAD",
    },
    tooltip: {
      mode: "OPEN",
      text: "Talk to me! 😊",
    },
    header: {
      title: (
        <div
          className="pointer m-0 fw-bold d-flex align-items-center gap-2"
          style={{
            fontSize: 20,
          }}
        >
          <img
            src={ai_avatar}
            alt="Logo"
            className="h-40p w-40p rounded-circle"
            loading="lazy"
          />
          Hermione
        </div>
      ),
      showAvatar: false,
    },
    notification: {
      disabled: true,
    },
    audio: {
      disabled: true,
    },
    chatHistory: {
      disabled: true,
    },
    chatInput: {
      disabled: false,
      allowNewline: false,
      enabledPlaceholderText: "Type your message...",
      disabledPlaceholderText: "",
      showCharacterCount: false,
      characterLimit: -1,
      botDelay: 1000,
      blockSpam: true,
      sendOptionOutput: true,
      sendCheckboxOutput: true,
    },
    chatWindow: {
      showScrollbar: false,
      autoJumpToBottom: false,
      showMessagePrompt: true,
      messagePromptText: "New Messages ↓",
      messagePromptOffset: 30,
      defaultOpen: false,
    },
    sensitiveInput: {
      maskInTextArea: true,
      maskInUserBubble: true,
      asterisksCount: 10,
      hideInUserBubble: false,
    },
    userBubble: {
      animate: true,
      showAvatar: false,
      simStream: false,
      streamSpeed: 30,
      dangerouslySetInnerHtml: false,
    },
    botBubble: {
      animate: true,
      showAvatar: false,
      simStream: false,
      streamSpeed: 30,
      dangerouslySetInnerHtml: false,
    },
    voice: {
      disabled: true,
    },
    footer: {
      text: <div className="d-flex align-items-center gap-2 pointer"></div>,
    },
    fileAttachment: {
      disabled: true,
      multiple: true,
      accept: ".png",
      sendFileName: true,
      showMediaDisplay: false,
    },
    emoji: {
      disabled: false,
      list: [
        "😀",
        "😃",
        "😄",
        "😅",
        "😊",
        "😌",
        "😇",
        "🙃",
        "🤣",
        "😍",
        "🥰",
        "🥳",
        "🎉",
        "🎈",
        "🚀",
        "⭐️",
      ],
    },
    toast: {
      maxCount: 3,
      forbidOnMax: false,
      dismissOnClick: true,
    },
    event: {
      rcbPreInjectMessage: false,
      rcbPostInjectMessage: false,
      rcbStartStreamMessage: false,
      rcbChunkStreamMessage: false,
      rcbStopStreamMessage: false,
      rcbRemoveMessage: false,
      rcbLoadChatHistory: false,
      rcbToggleChatWindow: false,
      rcbToggleAudio: false,
      rcbToggleNotifications: false,
      rcbToggleVoice: false,
      rcbChangePath: false,
      rcbShowToast: false,
      rcbDismissToast: false,
      rcbUserSubmitText: false,
      rcbUserUploadFile: false,
      rcbTextAreaChangeValue: false,
    },
    chatButton: {
      icon: ai_avatar,
      style: {
        background: "none",
      },
    },
  };

  return (
    <ChatBotProvider flow={flow} settings={settings}>
      <Chatbot />
      {liveAgentRequested && (
        <div style={formStyle}>
          <p>
            You have been transferred to a live agent. Please wait while we
            connect you.
          </p>
        </div>
      )}
    </ChatBotProvider>
  );
};

export default CustomChatbot;
